<template>
  <div class="module-box">
    <div class="module-title">联系我们</div>
    <div class="grid-box">
      <router-link to="/maintenance/list" class="grid-item mini" >
        <div class="icon">
          <img src="https://wxyy.hzrobam.com/uploads/20220322/7caf85da0077be390e42f9c77e9f8df2.svg" class="contain-img" alt="">
        </div>
        <span>故障自查</span>
      </router-link>
      <a href="javascript:;" class="grid-item mini" @click="onService">
        <div class="icon">
          <img src="~@/assets/images/icon/contact_icon_1.svg" class="contain-img" alt="">
        </div>
        <span>在线客服</span>
      </a>
      <a href="tel:9510-5855" class="grid-item mini">
        <div class="icon">
          <img src="~@/assets/images/icon/contact_icon_2.svg" class="contain-img" alt="">
        </div>
        <span>服务热线</span>
      </a>
      <router-link to="/feedback" class="grid-item mini">
        <div class="icon">
          <img src="~@/assets/images/icon/contact_icon_3.svg" class="contain-img" alt="">
        </div>
        <span>意见反馈</span>
      </router-link>
      <a href="https://work.weixin.qq.com/kfid/kfc13b6b5011a756105?enc_scene=ENCX2ZP57viwXNSm8jYnnVPZC" class="grid-item mini">
        <div class="icon">
          <img src="~@/assets/images/icon/contact_icon_2.svg" class="contain-img" alt="">
        </div>
        <span>服务管家</span>
      </a>
    </div>
  </div>
</template>

<script>
  import { Dialog, Toast } from 'vant'
  import wx from 'weixin-js-sdk'
  import { sendServiceMsg } from '@/api/common'
  export default {
    data() {
      return {

      }
    },
    mounted() {
      
    },
    methods: {
      onService() {
        Dialog.confirm({
          message: '是否前往咨询跳转老板服务公众号在线客服',
          confirmButtonColor: '#186AFF'
        })
        .then(() => {
          const toast = Toast.loading({
            message: '加载中...',
            forbidClick: true,
          })
          sendServiceMsg().then(() => {
            wx.closeWindow()
            // this.addServer()
            toast.clear()
          })
        })
        .catch(() => {
          // on cancel
        });
      }
    },
  }
</script>

<style lang="less" scoped>
  @import "../style.less";
</style>